import 'src/packages/car/CardCar.css.ts.vanilla.css?source=#H4sIAAAAAAAAA51WTW/bMAy991cIBQZsBwVOPzcXO+WynXschoKWGJurIhkSkzQb9t8HyXYcO86Q9BA4pvweyccPe7YArxfgX5SzDGTRv7zM+SFbbzLx50oITaE2sMvF0uDb05UQyhnnc7EB/1HKgKhlUGBQJrssPezklyz79HT192rWcdMKSlyMHcyTg8ibi0xkAtbsooctaa5y8TB7vPe4ipYKqax4YCqc1+hzMa/fRHCG9H8jAlNXIO9jWEIUoF5L79ZWy4lkcAWWSbXoGmr0skeMCQL9xlwot0E/OqldICZnc3GffYi/eL4CX5KVvkln3uYS0UvjtrmoSGu00dbDPRpg2mCftfSgaR1ykc1uW0HOEHv4QGe/SUU4S3JX/ELFckl8mPG5Ee0d/ghefb2+/jlsL+ssDkCxH9Fy6HC30+0Yr1KTR9WopZxZr+zTvrHmp8VckZVt4tnAdYWge+XuznPs3Taaf60D03In2/BzEWpQKAvkLcbKHrhhYrOX5b4ZBme57ampnuRd7UoPdbWTBgo0N9JjuTbg5R6YGjTdbdsKvoOogSYqQxZl9S6qA2hDhczoZRSEbHkx2wCdCPc1kVssXoll4VJputsUgDKwqnNxEw82FKggQ7zLm/8GD58v3Jt0nlLZNuiZFJh4zvjGsh9SNIbqQOHU6G6d17LwCK+5SBcJxkxM/6wbrMQPhkqbC4NLHnRJ4fSua5KHS2eg9ciuTqM5vzmaTQX+uUZ1tC8eL+r6FLwkxlXIhULLcTkcJgF6Yid9vsjHKJfjNZOcdNxfEvdQ8Wx2nP8QlBsILFVFRk/hB0iIDWLwmYHX4Sg3OMSfjnnpHPeg4iJBprfaZCkm9lIiRavH0o7ewvMsvrkOl7lydlEBM9myi1s1y4uM+f+HwcPRh4Fy9huC545Jv5dJQR216Hgw8ZzxrdIQDTZvNvvcDcpEMU7oe6LTRgv0SM1x2Jc0YIv9rnr8crqBjorfx30ina76s16JfQ4HtnHW/ckZ0t81NfwH3hCruIIKAAA=';
export var articleStatusContainer = 'CardCar_articleStatusContainer__1t60uva';
export var badge = 'CardCar_badge__1t60uv9';
export var badgeContainer = 'CardCar_badgeContainer__1t60uv8';
export var body = 'CardCar_body__1t60uv6';
export var caption = 'CardCar_caption__1t60uve';
export var captionIcon = 'CardCar_captionIcon__1t60uvf';
export var carSpecContainer = 'CardCar_carSpecContainer__1t60uv7';
export var container = 'CardCar_container__1t60uv0';
export var contents = 'CardCar_contents__1t60uv3';
export var footer = 'CardCar_footer__1t60uvb';
export var header = 'CardCar_header__1t60uv4';
export var iconChatting = 'CardCar_iconChatting__1t60uvc';
export var iconHeart = 'CardCar_iconHeart__1t60uvd';
export var image = 'CardCar_image__1t60uv2';
export var imageContainer = 'CardCar_imageContainer__1t60uv1';
export var title = 'CardCar_title__1t60uv5';