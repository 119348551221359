import type { ReactNode, ElementType, ComponentPropsWithRef } from 'react'
import classNames from 'classnames'
import * as css from './HideAnimation.css'

interface HideAnimationProps<T extends ElementType> {
  as?: T
  ref?: ComponentPropsWithRef<T>['ref']
  children?: ReactNode
  className?: string
  isHide?: boolean
  onAnimationEnd?: () => void
}

const HideAnimation = <T extends ElementType>({
  as,
  className,
  children,
  isHide,
  onAnimationEnd,
  ref,
  ...props
}: HideAnimationProps<T>) => {
  const Component = as as ElementType
  return (
    <Component
      ref={ref}
      className={classNames(css.root, className, { [css.hide]: isHide })}
      onAnimationEnd={onAnimationEnd}
      {...props}
    >
      {children}
    </Component>
  )
}

export default HideAnimation
