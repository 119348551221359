import "src/__internal__/_pages/PageSearchLab.css.ts.vanilla.css!=!../../../../.yarn/__virtual__/@vanilla-extract-webpack-plugin-virtual-bf5160efbb/0/cache/@vanilla-extract-webpack-plugin-npm-2.3.15-f9b744f022-c8c0053844.zip/node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/__internal__/_pages/PageSearchLab.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA5VUXY/aMBB851fsS6WeKiMH7gPMr1mcTbI9x45sB0ir/vfKDoRA6fX6FGUzuzOe2XipcV29HiX8XAB0WJZsawUSCk/tbgHQoq/Zir2L0bUK5PLl/KEP5EUgQzoqiHSKqah7H5xXgH10u8WvxXKcXuTpJYfO4KCgMnRK6PQUJXvSkZ1VoJ3pW7t7JGQatcqjKmejqLBlMyhg25DnzI9dR+jRalJgnaVU2ztfklcg5wI7xzaS3/1NloLikULvjqn8vQ+Rq0FoZyPZqEDTZRoarq3gSG2Yl68HWm6KVfJwcnjUJzyW3IcEWL9dPN6jfq+9622p4ID+qxCBqBRBoyGhnXFe1B4HUUj5lPDiSPt3jiJiJxquG8N1E0eggujRhg492WxVfuXxWFeeEQxrKdswd12hjnygbP49+mNtaLoGJ4UfssobSvgGN5mfNzG67rqGE/r58YY9TGNqeslNnzjD9qw+r13gH5RznGLK5SMlsxXsnSmvP06Wun1OecsZ82tmbs4tRXfa/dNXatFG1mdZJR84bU3xNOcq7nneMo/hEEWIgyGVLNKzhRSGqqRguZr/7up2iFIt+nfy/2fX1L+Zxzfy/ZHf9saQjTw0c5GX2+fuLsBbF6X8kprcgXxl3FEM0z30G9wFW5/lBAAA\"}!../../../../.yarn/__virtual__/@vanilla-extract-webpack-plugin-virtual-bf5160efbb/0/cache/@vanilla-extract-webpack-plugin-npm-2.3.15-f9b744f022-c8c0053844.zip/node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var actions = 'ca3f6w1';
export var button = 'ca3f6w2';
export var buttonText = 'ca3f6w8';
export var contents = 'ca3f6wa';
export var description = 'ca3f6w3';
export var divider = 'ca3f6w6';
export var header = 'ca3f6w4';
export var list = 'ca3f6w7';
export var scrollContainer = 'ca3f6w9';
export var section = 'ca3f6w0';
export var title = 'ca3f6w5';