import 'src/packages/commerce/CardCommerce.css.ts.vanilla.css?source=#H4sIAAAAAAAAA51WTY/aMBC98yuiSistB0cBll02qCdOPa3Ua1WtJvYkeHHsyHb4aLX/vYrzSTCU9oBQnMzMm+f3xg43oNlG5Tlqiu+lQf0dgVqu5AaK6s+8vx92tJgvo+D3JAgYN4WAUxykAo/rSRDkoDMuiVVFHEBpVbX2URrL0xOhSlqUtv6YoGTryeckPKtI6yptkZkrQpVQOg72oB8JMYiMGAoCiVsnmYYTeY6iaVUqVdISw39hHEThajZfaszXPpwgeCYJt5ibOKAoLeoBfM2zra1SdAkEl0i2WK/Poujh79hjAcYSuuWCuTZGuS8TGCXYW2nbBHMXddbRSwvH4tES18MQfQGMcZl1wIMoXD4vmpAE6C7TqpSMeAi1YDltGG0eEgF0R0AUWyDLht9CGV41GQeQGCVKiy61slblrqfq4VjhdTgSpRlqkijH+YEzu23Z826rB8Vhyy262uSAyY5b4npPuRBtH/emuML32x61gFNL+8LRfputsfxakqaDNp/73Wp1M1jyE+ls41gUmPpFkgrIOhM+DYVVB4eLlyui92VqEy1jqezjQLFTn2TD2Wrlco9TFZpTbHM9u9BzwyyWD+PpEIW9PQcin3kLQK5K2TnjpXfGoamQKOEZJ4wbOgxcXQu84XzP9mMOsldZoXkO+nRbo/eEXwwUJS1wibqF/+qfuQKtRU1MAdS5jkRhNB8OCoZUaajlJpXEnnJ7Elit6RzElWbHWn91o2CMleeQ4WYMGGq+BR7jIAqi7kS4yyK3LTgiEYpqznQR01GCWltU7etBOXjTG3EZPVS/SzHMGkBVdCrUIQ62nDGU5z7WKMDyfTMQ3dzTwHhphqa8lzbPV+3LxHF6F4Mq+UBa6dAOe/8nbF3VH0bTr1++/DyXYK2mcWQOvDsGqV+z7grAuEZas0eVKHO57tQyu05uziVp2vfMR8ut6FCz66PoYuQ0Xuk3GYXgheHGCbY6QpzDnF0OGoprivhvCzX3h2+0v0Ogn7yLC1V/BbjzXjO7Nd7GcJ/8jpepulBu6jmQ2lqffwA5ytWKXQoAAA==';
export var amount = 'CardCommerce_amount__wkcp257';
export var caption = 'CardCommerce_caption__wkcp251';
export var captionIcon = 'CardCommerce_captionIcon__wkcp25e';
export var container = 'CardCommerce_container__wkcp259';
export var discount = 'CardCommerce_discount__wkcp258';
export var flag = 'CardCommerce_flag__wkcp255';
export var flags = 'CardCommerce_flags__wkcp254';
export var image = 'CardCommerce_image__wkcp25b';
export var imageContainer = 'CardCommerce_imageContainer__wkcp25a';
export var infoContainer = 'CardCommerce_infoContainer__wkcp25f';
export var main = 'CardCommerce_main__wkcp25c';
export var price = 'CardCommerce_price__wkcp256';
export var soldOut = 'CardCommerce_soldOut__wkcp252';
export var soldOutOverlay = 'CardCommerce_soldOutOverlay__wkcp253';
export var title = 'CardCommerce_title__wkcp25d';
export var userReactionCaptions = 'CardCommerce_userReactionCaptions__wkcp250';