import 'src/packages/community/CardCommunityExperiment.css.ts.vanilla.css?source=#H4sIAAAAAAAAA+1XPY/jNhDt/SuIAwJkCxqWbz/utEhlXJH2kCBFECwocizNLkUKJGVbCe6/B6JkybJkW/I6QIotDNv8eDPDN3wznK+YESudprlCV3zbZWAwBeVeUoZqpZVjqMC8vATBq0jv+YL8MyNEoM0kK0KylrB7nhH/TQUa4A61CgnXMk/V8+zHbH4JH5TrmwmmmSEkZSZGRQ3GiQvJYv64fDCQnnWAnzK+HDb+mluH64LW+0JiM8aBRuC2AIdOOJ2VLlx0wOZR3/jnKyOvjX55GhF3mg7Gfe9Nr7Vy1OLfEJINMz9TagEEtZAy5ZBTV2Q6NixLChppUdB0SQ3EuWSGNjvvnvc4W6gIuQap2uuxJCqgyXVYB3srLHAODC3JQxVPh+ts94hcS226QJxJoH6cxoYV9Gmx8EszJoS3upjXRBESaSPAUMME5rac+dxOMf4WG50rQQeM7L2t7GQsA7NPTnt3NgUiJmJYMQexNkXD/8NE/iWLQD7cgP4hoCvZP4J6J/nHaH3uexeVSYwVRQepDQkH5cAMikc7dZATgVetbgocZ0ewbKd25Xn7vfWySHsntgk68H5CSJTeGpZdTqfjnA3qnJ2Q3l0VZrnTZ9PQoZPQ16HHq3QouJkOBTfUoeC2OhS8U4e+eqLOUCLAcoNZWWT6xDx5YroFp8nGj7rxn9WNM3xhyuKBK/SlukISdiFZkEV9FQnZonBJSO7ne9b2h9OOTK857Y678fWsyhSuN5UIHsxk2mLV5Dwsfio/XiSbQQOSOdxAOVruXku9DUmCQlRN2MXWyejtoCL7daDEgZBJWI/RsZMknN/RLPzq2RrBjY5egTu6Rnd4dKfO+5LHrf0/reG/fPr0V7f3VFrBmLhz5Roo5iFatlhktcydZ6tSjOoulee6mJpuZcSSFVRgetRNdbJsf25BK05jy3RTRp3TaVXHBhP5o0ma0iQNEOtYS2v9xzct59Wu5LvMKyZ/VWvdv3HRuMdTrQBDz7UTmXHGp9yC+Q7MQ9u+S2KCSyOt9I3ApLhP5P7xE/ryC/qCU6Fk1lGeoBSHjcPewHlp4UOQ6+E4RxTS+0uF9DCW35I8jX7PusoWT+xKOfNd1C3anGGoKy9/D+yd17+Pd1Wn89h9QtQVonntjGTuO2Sy6PKWfPD2/+St9H8lWZr9gS75JiVmFm1DG3avOt1C9IZu/8DdoMUIJboirH5L32EcrKLaoG/rNmAcciZ9BwI7R9umEWqjp1rJrTaCRgbYW0j8F2VSXhFTM/oSNPG9+vj2DnsuebkkJMG7LCwbC28nLSyfZz/+BcsPT0r4FQAA';
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var additionalInfoContainer = 'CardCommunityExperiment_additionalInfoContainer__11jdm4cb';
export var badgeCategory = 'CardCommunityExperiment_badgeCategory__11jdm4c5';
export var commentContainer = 'CardCommunityExperiment_commentContainer__11jdm4c4';
export var contentContainer = 'CardCommunityExperiment_contentContainer__11jdm4c2';
export var descriptionContainer = 'CardCommunityExperiment_descriptionContainer__11jdm4c7';
export var iconContainer = 'CardCommunityExperiment_iconContainer__11jdm4cf';
export var image = 'CardCommunityExperiment_image__11jdm4c9';
export var imageContainer = 'CardCommunityExperiment_imageContainer__11jdm4c8';
export var imageCount = 'CardCommunityExperiment_imageCount__11jdm4ca';
export var lineClampWithEllipsis = _7a468({defaultClassName:'CardCommunityExperiment_lineClampWithEllipsis__11jdm4ci',variantClassNames:{lineClamp:{'1':'CardCommunityExperiment_lineClampWithEllipsis_lineClamp_1__11jdm4cj','2':'CardCommunityExperiment_lineClampWithEllipsis_lineClamp_2__11jdm4ck'}},defaultVariants:{lineClamp:2},compoundVariants:[]});
export var mainContainer = 'CardCommunityExperiment_mainContainer__11jdm4c0';
export var mainContentContainer = 'CardCommunityExperiment_mainContentContainer__11jdm4c1';
export var subContainer = 'CardCommunityExperiment_subContainer__11jdm4c3';
export var tagGroupContainer = 'CardCommunityExperiment_tagGroupContainer__11jdm4cc';
export var titleContainer = 'CardCommunityExperiment_titleContainer__11jdm4c6';
export var userReactionContainer = 'CardCommunityExperiment_userReactionContainer__11jdm4ce';
export var userReactionReplyCount = 'CardCommunityExperiment_userReactionReplyCount__11jdm4ch';
export var userReactionThumbUpCount = 'CardCommunityExperiment_userReactionThumbUpCount__11jdm4cg';
export var userReactionsContainer = 'CardCommunityExperiment_userReactionsContainer__11jdm4cd';