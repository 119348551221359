import type { ReactNode, ComponentPropsWithRef, ElementType } from 'react'
import classNames from 'classnames'
import { TouchableHighlight } from '../_app/components/touchable-highlight'
import * as css from './CardFleaMarketItem.css'
import type { AsProps } from '../_app/types/as-props'

interface CardFleaMarketItemProps<T extends ElementType> {
  as?: T
  ref?: ComponentPropsWithRef<T>['ref']
  role?: string
  className?: string
  onClick?: () => void
  children?: ReactNode
}

const CardFleaMarketItem = <T extends ElementType>({
  as,
  role,
  className,
  children,
  onClick,
  ref,
}: CardFleaMarketItemProps<T>) => {
  return (
    <TouchableHighlight
      ref={ref}
      as={as || 'div'}
      role={role}
      className={classNames(css.listItem, className)}
      onClick={onClick}
    >
      {children}
    </TouchableHighlight>
  )
}

export default CardFleaMarketItem
