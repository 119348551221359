import { assignInlineVars } from '@vanilla-extract/dynamic'
import { useMemo } from 'react'
import * as css from './CardPlace.css'
import { commaNumber } from '../_app'
import {
  ADAPTIVE_IMAGE_FORMAT,
  ADAPTIVE_IMAGE_RESIZABLE_OPTION,
  AdaptiveImage,
} from '../adaptive-image'
import { TagGroup } from '../tag-group'
import { Tag } from '../tag'
import { Badge } from '../badge'
import { vars } from '@seed-design/design-token'
import { IconStarFill } from '@daangn/react-monochrome-icon'
import { displayLocalProfileDistance } from '../_app/utils/displayLocalProfileDistance'

interface CardPlaceProps {
  title: string
  regionName: string
  descriptionWithoutEmoji: string
  operationStatus: string
  reviewStarRatingAverage: number
  imagesCount: number
  thumbnail?: string
  reviews?: number
  followers?: number
  categoryName?: string
  showPurchase?: boolean
  reservable?: boolean
  hasCoupon?: boolean
  distance?: number
  bestReview?: { userName: string | null; content: string | null }
  showReview?: boolean
}

const CardPlace = (props: CardPlaceProps) => {
  const showReviews = useMemo(() => {
    return !!(props.reviews && props.reviews > 0)
  }, [props.reviews])

  const showFollowers = useMemo(() => {
    return !!(props.followers && props.followers > 0)
  }, [props.followers])

  const showReviewStarRatingAverage = useMemo(() => {
    return !!(props.reviewStarRatingAverage >= 1)
  }, [props.reviewStarRatingAverage])

  const commaReviewCount = useMemo(() => {
    return props.reviews ? commaNumber(props.reviews) : 0
  }, [props.reviews])

  const commaFollowerCount = useMemo(() => {
    return props.followers ? commaNumber(props.followers) : 0
  }, [props.followers])

  const a11yLabel = useMemo(() => {
    return `동네업체 ${props.title} ${props.regionName} ${
      props.descriptionWithoutEmoji ? `${props.descriptionWithoutEmoji} ` : ''
    }후기${showReviews ? props.reviews : 0} 단골${
      showFollowers ? props.followers : 0
    } ${props.categoryName ? `${props.categoryName} ` : ''}${
      props.reservable ? '예약 ' : ''
    }${props.showPurchase ? '결제 ' : ''}${props.hasCoupon ? '쿠폰 ' : ''}${
      props.distance ? displayLocalProfileDistance(props.distance) : ''
    }`
  }, [
    props.title,
    props.regionName,
    props.descriptionWithoutEmoji,
    showReviews,
    props.reviews,
    showFollowers,
    props.followers,
    props.categoryName,
    props.showPurchase,
    props.hasCoupon,
    props.reservable,
    props.distance,
  ])

  return (
    <div className={css.button}>
      <div className={css.bizInfoContainer} aria-label={a11yLabel}>
        <div className={css.contents}>
          <div className={css.titleContainer}>
            <span className={css.title}>{props.title}</span>
            <span className={css.categoryName}>{props.categoryName}</span>
          </div>
          <div className={css.body}>
            <Tags
              regionName={props.regionName}
              reviewCount={commaReviewCount}
              showFollowers={showFollowers}
              showReviews={showReviews}
              showReviewStarRatingAverage={showReviewStarRatingAverage}
              followerCount={commaFollowerCount}
              distance={props.distance}
              operationStatus={props.operationStatus}
              reviewStarRatingAverage={props.reviewStarRatingAverage}
            />
            {props.descriptionWithoutEmoji ? (
              <div className={css.bodyContents}>
                {props.descriptionWithoutEmoji}
              </div>
            ) : null}
            <Badges
              reservable={props.reservable}
              showPurchase={props.showPurchase}
              hasCoupon={props.hasCoupon}
            />
          </div>
        </div>
        {props.thumbnail ? (
          <div
            className={css.imageContainer}
            style={assignInlineVars({
              [css.imageCountVar]: `"${props.imagesCount}"`,
              [css.displayImageCountVar]:
                props.imagesCount <= 1 ? 'none' : 'flex',
            })}
          >
            <AdaptiveImage
              className={css.image}
              src={props.thumbnail}
              options={[
                {
                  size: '300x300',
                  quality: 82,
                  type: ADAPTIVE_IMAGE_RESIZABLE_OPTION.CROP,
                  format: ADAPTIVE_IMAGE_FORMAT.WEBP,
                },
              ]}
            />
          </div>
        ) : null}
      </div>
      {!!props.showReview && props.bestReview ? (
        <div className={css.reviewContainer}>
          <div className={css.commentContainer}>
            <div className={css.lineClampWithEllipsis}>
              {props.bestReview.userName ? (
                <span aria-label="후기 작성자" className={css.reviewWriter}>
                  {props.bestReview.userName}&nbsp;
                </span>
              ) : null}
              {props.bestReview.content ? (
                <span aria-label="실제 후기">{props.bestReview.content}</span>
              ) : null}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  )
}

interface TagsProps {
  regionName: string
  showReviews: boolean
  showFollowers: boolean
  showReviewStarRatingAverage: boolean
  reviewCount: number | string
  followerCount: number | string
  operationStatus: string
  distance?: number
  reviewStarRatingAverage: number
}

const Tags = (props: TagsProps) => {
  return (
    <div className={css.reactionContainer}>
      {props.showReviews || props.showFollowers ? (
        <div className={css.reviewAndFollowerTagGroup}>
          <TagGroup>
            {props.showReviewStarRatingAverage ? (
              <Tag
                color="gray900"
                large
                bold
                iconClassName={css.starIcon}
                icon={
                  <IconStarFill
                    width="0.9375rem"
                    height="0.9375rem"
                    color={vars.$semantic.color.primary}
                  />
                }
              >
                {props.reviewStarRatingAverage.toFixed(1)}
              </Tag>
            ) : null}
            {props.showReviews ? (
              <Tag large color="gray900">
                후기 <strong>{props.reviewCount}</strong>
              </Tag>
            ) : null}
            {props.showFollowers ? (
              <Tag large color="gray900">
                단골 {props.followerCount}
              </Tag>
            ) : null}
          </TagGroup>
        </div>
      ) : null}
      <TagGroup UNSAFE_className={css.operationDistanceRegionTagGroup}>
        {props.operationStatus ? (
          <Tag color="gray700" medium>
            {props.operationStatus}
          </Tag>
        ) : null}
        {props.distance ? (
          <Tag color="gray700" medium>
            {displayLocalProfileDistance(props.distance)}
          </Tag>
        ) : null}
        {props.regionName ? (
          <Tag color="gray700" medium>
            {props.regionName}
          </Tag>
        ) : null}
      </TagGroup>
    </div>
  )
}

interface BadgesProps {
  reservable?: boolean
  showPurchase?: boolean
  hasCoupon?: boolean
}

export const Badges = (props: BadgesProps) => {
  if (!props.reservable && !props.showPurchase && !props.hasCoupon) {
    return null
  }

  return (
    <div className={css.badgeContainer}>
      {props.reservable ? (
        <Badge
          className={css.badge}
          variant="primary"
          size="medium"
          type="normal"
          shape="square"
        >
          예약
        </Badge>
      ) : null}
      {props.showPurchase ? (
        <Badge
          className={css.badge}
          variant="basic"
          size="medium"
          type="normal"
          shape="square"
        >
          결제
        </Badge>
      ) : null}
      {props.hasCoupon ? (
        <Badge
          className={css.badge}
          variant="basic"
          size="medium"
          type="normal"
          shape="square"
        >
          쿠폰
        </Badge>
      ) : null}
    </div>
  )
}

export default CardPlace
