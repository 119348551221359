import 'src/packages/flea-market/CardFleaMarket.css.ts.vanilla.css?source=#H4sIAAAAAAAAA51WTW+cMBC976+wIlVqDkaQZDcJUU+RWvXQU49VFQ32LEzW2Mj2fpCq/70yEGB32XSTAwI8zJuZN29sokew8qtC+AF2hf5JGO2BNNqnp6S2itxLzP7MGJPkKgV1ypYKdw8zxoRRxqZsA/Yz5w5RcidAIW/WeW6h5vdxfPkw+zuLDmJQCTk+HgVKmkBbkr5I2SK6nVssQ6QCKS/83lJmrESbsqTaMWcUyTfzAFUVwOchGcYyEKvcmrWWfKIELEF7Ep13BRVaPngcAjh6wZQJs0F7YKmMI09Gp2wefwpXsJdgc9LctuUkXS3Be6nMNmUFSYk6rA3uFhV42uBQNbcgae1SFkfXPSGhKymLWcxg7c27SJ/8sLdfnd8Ukz2j8HxJfszJUc5t3ScSHOL+clZ8ubj4va8+bTROOZdAuve9nlZsy1Hy+sglWRQty8KodfkG8yVp3nEQT4X35NWQ+00Tf2m07xQypTBfVya3UBU1z4yseZlwi/lageW9Z6O35m3b0f0RpNa3wVKkkRcfwxr5tljoPVruKhCk8/fD7blfjuYjM96bMmglXly9iqvvJt9itiLPM9M09fW1yU4oKKuUXQXDhhxlpMjXafuscPx9ZnbcWEIdiEDrSYAKdo87z4eRRKWocuRODerWWMkzi7BKWXPjoNSUQpwHv3a9RObTEu0Y8KYK5Sdd9afQvkHVAy4awP0dJo5OAiwt4s8CLOm8h7idzgkU5ZqTx9KlTKD27WCP5B1Hd8NGNFZrZpScil5ZEsO43B2Oy7vxBFRhZgd676dLeV47T8uah0Ou6XyzD6CWh9yf2kS7QH0caOKccRQu4vjymLXkWN7/Yf1Egw8mO4nDgXNG+qkC57koSMkp/byB8V2McLIz6R7qOFFet8cm0cBMX9No7ZCFwXJGK27av5J/99/nRvkIAAA=';
export var caption = 'CardFleaMarket_caption__1yrlisza';
export var captionIcon = 'CardFleaMarket_captionIcon__1yrliszb';
export var captions = 'CardFleaMarket_captions__1yrlisz9';
export var container = 'CardFleaMarket_container__1yrlisz0';
export var freeSharing = 'CardFleaMarket_freeSharing__1yrlisz7';
export var image = 'CardFleaMarket_image__1yrlisz2';
export var imageContainer = 'CardFleaMarket_imageContainer__1yrlisz1';
export var main = 'CardFleaMarket_main__1yrlisz3';
export var price = 'CardFleaMarket_price__1yrlisz8';
export var status = 'CardFleaMarket_status__1yrlisz5';
export var statusGap = 'CardFleaMarket_statusGap__1yrlisz6';
export var title = 'CardFleaMarket_title__1yrlisz4';