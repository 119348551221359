import { useMemo } from 'react'
import * as css from './CardCommunityExperiment.css'
import { Highlight } from '../highlight'
import {
  ADAPTIVE_IMAGE_FORMAT,
  ADAPTIVE_IMAGE_RESIZABLE_OPTION,
  AdaptiveImage,
} from '../adaptive-image'
import { Tag } from '../tag'
import { TagGroup } from '../tag-group'
import {
  IconHorizline2VerticalChatbubbleRectangularRightFill,
  IconThumbUpFill,
} from '@daangn/react-monochrome-icon'

const getTextWithoutHTMLTag = (text: string) => {
  return text.replace(new RegExp('<[^>]*>', 'g'), '')
}
interface CommunitySingleViewProps {
  thumbnail?: string
  categoryName: string
  interestName?: string
  titleHighlight: string
  contentHighlight: string
  commentHighlight?: string
  createdAt?: string
  regionName: string
  commentsCount: number
  emotionsCount: number
  imagesCount: number
  viewCount: number
  hideBorder?: boolean
}

const CardCommunityExperiment = (props: CommunitySingleViewProps) => {
  const badgeCategoryText = useMemo(() => {
    return props.interestName || props.categoryName
  }, [props.interestName, props.categoryName])
  const showImageCount = useMemo(() => {
    return props.imagesCount && props.imagesCount > 1
  }, [props.imagesCount])

  const a11yLabelMainContent = useMemo(() => {
    return `동네 생활 카테고리 ${badgeCategoryText} 제목 ${getTextWithoutHTMLTag(
      props.titleHighlight
    )} 내용 ${getTextWithoutHTMLTag(props.contentHighlight)} 감정표현 ${
      props.emotionsCount
    } 댓글 ${props.commentsCount} ${props.regionName} ${props.createdAt}${
      props.viewCount ? `조회 ${props.viewCount}` : ''
    }`
  }, [
    badgeCategoryText,
    props.titleHighlight,
    props.contentHighlight,
    props.emotionsCount,
    props.commentsCount,
    props.regionName,
    props.createdAt,
    props.viewCount,
  ])
  const a11yLabelSubContent = useMemo(() => {
    return props.commentHighlight
      ? `
      베스트 댓글 ${getTextWithoutHTMLTag(props.commentHighlight)}
    `
      : ''
  }, [props.commentHighlight])

  return (
    <div aria-label={a11yLabelMainContent}>
      <div className={css.mainContainer}>
        <div className={css.badgeCategory}>{badgeCategoryText}</div>
        <div className={css.contentContainer}>
          <div>
            <div className={css.mainContentContainer}>
              <div className={css.titleContainer}>
                <Highlight
                  className={css.lineClampWithEllipsis({ lineClamp: 2 })}
                  dangerouslySetInnerHTML={{
                    __html: props.titleHighlight,
                  }}
                />
              </div>
              <div className={css.descriptionContainer}>
                <Highlight
                  className={css.lineClampWithEllipsis({ lineClamp: 1 })}
                  dangerouslySetInnerHTML={{
                    __html: props.contentHighlight,
                  }}
                />
              </div>
            </div>
            <div className={css.additionalInfoContainer}>
              <div className={css.tagGroupContainer}>
                <div className={css.userReactionsContainer}>
                  {props.emotionsCount ? (
                    <div className={css.userReactionContainer}>
                      <div className={css.iconContainer}>
                        <IconThumbUpFill size={14} />
                      </div>
                      <div className={css.userReactionThumbUpCount}>
                        {props.emotionsCount}
                      </div>
                    </div>
                  ) : null}
                  {props.commentsCount ? (
                    <div className={css.userReactionContainer}>
                      <div className={css.iconContainer}>
                        <IconHorizline2VerticalChatbubbleRectangularRightFill
                          size={14}
                        />
                      </div>
                      <div className={css.userReactionReplyCount}>
                        {props.commentsCount}
                      </div>
                    </div>
                  ) : null}
                </div>
                <TagGroup small>
                  <Tag small>{props.regionName}</Tag>
                  <Tag small>{props.createdAt}</Tag>
                  {props.viewCount ? (
                    <Tag small>조회 {props.viewCount}</Tag>
                  ) : null}
                </TagGroup>
              </div>
            </div>
          </div>
          {props.thumbnail ? (
            <div className={css.imageContainer}>
              <AdaptiveImage
                className={css.image}
                src={props.thumbnail}
                options={[
                  {
                    size: '300x300',
                    quality: 32,
                    type: ADAPTIVE_IMAGE_RESIZABLE_OPTION.CROP,
                    format: ADAPTIVE_IMAGE_FORMAT.WEBP,
                  },
                ]}
              />
              {showImageCount ? (
                <div className={css.imageCount}>{props.imagesCount}</div>
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
      {props.commentHighlight ? (
        <div className={css.subContainer} aria-label={a11yLabelSubContent}>
          <div className={css.commentContainer}>
            <Highlight
              className={css.lineClampWithEllipsis({ lineClamp: 2 })}
              dangerouslySetInnerHTML={{
                __html: props.commentHighlight,
              }}
            />
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default CardCommunityExperiment
