import type { PropsWithChildren } from 'react'
import { forwardRef } from 'react'
import classNames from 'classnames'
import type {
  TouchableHighlightAs,
  TouchableHighlightElement,
} from '../_app/components/touchable-highlight'
import { TouchableHighlight } from '../_app/components/touchable-highlight'
import * as css from './TouchableCardShortform.css'

interface Props {
  as: TouchableHighlightAs
  role?: string
  className?: string
  hideBorder?: boolean
  onClick?: () => void
}

const CardShortformItem = forwardRef<
  TouchableHighlightElement<TouchableHighlightAs>,
  PropsWithChildren<Props>
>(({ as, role, className, hideBorder = false, children, onClick }, ref) => {
  return (
    <TouchableHighlight
      ref={ref}
      as={as}
      role={role}
      data-hide-border={hideBorder}
      className={classNames(css.container, className)}
      onClick={onClick}
    >
      {children}
    </TouchableHighlight>
  )
})

CardShortformItem.displayName = 'CardShortformItem'

export default CardShortformItem
