import type { MouseEvent, ReactNode } from 'react'
import { Fragment } from 'react'
import * as css from './AutoCompleteKeyword.css'
import { IconMagnifyingglassLine } from '@daangn/react-monochrome-icon'
import { vars } from '@seed-design/design-token'

export interface AutoCompleteKeywordType {
  readonly name: string
  readonly targetUri?: string
}

interface AutoCompleteKeywordProps {
  keyword: AutoCompleteKeywordType
  icon?: ReactNode
  query: string
  index: number
  isCategory?: boolean
  onClick: (
    e: MouseEvent<HTMLDivElement>,
    info: {
      keyword: AutoCompleteKeywordType
      query: string
      index: number
    }
  ) => void
}

const AutoCompleteKeyword = (props: AutoCompleteKeywordProps) => {
  const splitted = props.keyword.name.split(props.query)

  const onClick = (e: MouseEvent<HTMLDivElement>) => {
    props.onClick(e, {
      keyword: props.keyword,
      query: props.query,
      index: props.index,
    })
  }

  const a11yLabel = `${props.isCategory ? '카테고리 ' : ''}${
    props.keyword.name
  }`

  return (
    <div
      className={css.container}
      role="button"
      aria-label={a11yLabel}
      onClick={onClick}
    >
      <div className={css.iconContainer}>
        {props.icon ? (
          <>{props.icon}</>
        ) : (
          <IconMagnifyingglassLine
            size={16}
            color={vars.$scale.color.gray600}
          />
        )}
      </div>
      <div className={css.labelContainer}>
        <label className={css.autoCompletionLabel}>
          {splitted.map((word, wordIndex) => {
            const isLast = wordIndex === splitted.length - 1

            return (
              <Fragment key={wordIndex}>
                <>{word}</>
                {!isLast && (
                  <mark className={css.highlight}>{props.query}</mark>
                )}
              </Fragment>
            )
          })}
        </label>
        {props.isCategory ? (
          <span className={css.autoCompletionCategory}>카테고리</span>
        ) : null}
      </div>
    </div>
  )
}

export default AutoCompleteKeyword
