export const COLLECTION_TYPE = {
  AD: 'AD',
  FLEA_MARKET: 'FLEA_MARKET',
  COMMUNITY: 'COMMUNITY',
  BUSINESS: 'BUSINESS',
  PLACE: 'PLACE',
  SHORTCUT: 'SHORTCUT',
  JOB: 'JOB',
  COMMERCE: 'COMMERCE',
  CAR: 'CAR',
  REALTY: 'REALTY',
  KARROT_GROUP: 'KARROT_GROUP',
  CATALOG_PRODUCT_AD: 'CATALOG_PRODUCT_AD',
  SURVEY: 'SURVEY',
  INTENT_KEYWORD_CHIP: 'INTENT_KEYWORD_CHIP',
  SHORTFORM: 'SHORTFORM',
  RELATED_KEYWORD: 'RELATED_KEYWORD',
  UNKNOWN: 'UNKNOWN',
} as const

export type CollectionType =
  (typeof COLLECTION_TYPE)[keyof typeof COLLECTION_TYPE]
