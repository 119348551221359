import 'src/packages/community/CardCommunity.css.ts.vanilla.css?source=#H4sIAAAAAAAAA+1YTY/bNhC9+1cQAQJ0DzQkZz8SLXoyesg1aNFDUSwocizNLkUKJGWvWuS/F6IsS7KkjWU7QA97MGTz481w3vDNyMs1M2Kts6xQ6MqnjKFaa+UYKjBPT5jbWxEE5N8FIQJtLlkZkY2E18cF8U8q0AB3qFVEuJZFph4X3xfLcVBQboAdzsMmJGMmQUUNJqmLSLC8X90ZyN62+ie69GvGEhiYX51m3uhdx7bTeWV5wq4t4oGZT2eecm/p88OELa6zbCyqt97eRitHLf4DEdky8wulFkBQCxlTDjl1Za4Tw/K0pLEWJc1W1EBSSGboYefNY4Ozgzri5yDVez2WRAU0PQ+rs7fGAufAUJszjiqZD9fb7hG5ltr0gTiTQP04TQwr6UMQ+KU5E8JbDZZ7dgiJtRFgqGECC1vNfGqnGH9JjC6UoCNGGm9rOznLwVBe5669GeE9ZiKBNXOQaFM2pN/NJF2yGOTdFTgfAzqT8iOoCxk/RhsSPriSTGKiKDrIbEQ4KAemGn4urMNN2ZDSneokQui1qM/7cUqEq3bqtYq337tfFmvvxC5FB95PiIjSO8PyH+fQcaKG+0SdkdN9bWWF0yO559DJoZDen6U44dUUJ7yi4oTXVZzwQsX54tkZ8CDAcoN5VTkGbDx4NvpF5JB372Xhp5WFAUk42nV8ri+LhNeIBCTY3zRCdihcGpHbZUNVE5F2ZH4daXfcnF6j6vTgeltrXGcm1xbrbuUu+Fh9vAYeBg1I5nAL1Wi1eyP1LiIpCgFqVHAnWq2B4Pp1oERHpyRspmVqKvJjy5rZL56XE1jQ8TNwRzfoukGaiuy4bwejf1nDf/3w4e9+j6i0guljFco1+5nf1zLAYqtl4TwD9dWvL0UVq2BuClVnk6ykArOjrqeXOU2EwlZlTq2sh8rnnM7q0jOanO99zZy+ZoRYx1pa9z98nzEmWxXLVTYx+VVt9OAWxRe/NU2kw8CRwoL5BswD2oEfYpYfF0tK15mBL3BOTA5vsVMvsW+bjCSzjvIUpegW/AZ1TD74CM6mK3thp0Ntr3U7dkItvB2vhd2z/J4WWfxH3hOyZGYLyZlvfq7RQ45DnXnXB2AX3vYh3lkdyn2/yd8XhAOzb9L1DXJZ9shK38n6P5FVeb2WLMurv7p+kxJzi7bhCvvSRHcQv6BrXja3aDFGia6M6u/Stw6dVVQb9IK5BeOQM+lbC3h1tO3wYG9zqu/baSNobIC9RMQ/KJPy5IMcRp/C5lDP/lCNl541Xq2ISHgG7KqBfZmEXT0uvv8Hl5pkIi4VAAA=';
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var additionalInfoContainer = 'CardCommunity_additionalInfoContainer__ips4d0b';
export var badgeCategory = 'CardCommunity_badgeCategory__ips4d05';
export var commentContainer = 'CardCommunity_commentContainer__ips4d04';
export var descriptionContainer = 'CardCommunity_descriptionContainer__ips4d07';
export var iconContainer = 'CardCommunity_iconContainer__ips4d0f';
export var image = 'CardCommunity_image__ips4d09';
export var imageContainer = 'CardCommunity_imageContainer__ips4d08';
export var imageCount = 'CardCommunity_imageCount__ips4d0a';
export var lineClampWithEllipsis = _7a468({defaultClassName:'CardCommunity_lineClampWithEllipsis__ips4d0i',variantClassNames:{lineClamp:{'1':'CardCommunity_lineClampWithEllipsis_lineClamp_1__ips4d0j','2':'CardCommunity_lineClampWithEllipsis_lineClamp_2__ips4d0k'}},defaultVariants:{lineClamp:2},compoundVariants:[]});
export var mainContainer = 'CardCommunity_mainContainer__ips4d00';
export var mainContentContainer = 'CardCommunity_mainContentContainer__ips4d01';
export var mainContentWithImageContainer = 'CardCommunity_mainContentWithImageContainer__ips4d02';
export var subContainer = 'CardCommunity_subContainer__ips4d03';
export var tagGroupContainer = 'CardCommunity_tagGroupContainer__ips4d0c';
export var titleContainer = 'CardCommunity_titleContainer__ips4d06';
export var userReactionContainer = 'CardCommunity_userReactionContainer__ips4d0e';
export var userReactionReplyCount = 'CardCommunity_userReactionReplyCount__ips4d0h';
export var userReactionThumbUpCount = 'CardCommunity_userReactionThumbUpCount__ips4d0g';
export var userReactionsContainer = 'CardCommunity_userReactionsContainer__ips4d0d';