// graphql 호환성 위해 추가, 지원 버전이 chrome 64에서 올라가면 다시 체크
import 'core-js/actual'
// 여기까지
import '@stackflow/plugin-basic-ui/index.css' // stackflow
import './_app/styles/globalSeedDesign.css' // seed-design
import './_app/styles/global.css'
import '@daangn/search-sdk/lib/index.css'
import '@daangn/pull-to-refresh/lib/index.css'
import '@daangn/karrotflyer/index.css'
import './_app/styles/entry'

import { StrictMode } from 'react'
import { createRoot, hydrateRoot } from 'react-dom/client'
import { install } from 'resize-observer'
import App from './App'
import { STAGE } from './_app/constants/common'
import { initSentry } from './_app/utils/initSentry'
import {
  createDatadogRumGlobalContext,
  initDatadogRum,
} from './_app/utils/initDatadogRum'
import { QueryClient, isServer } from '@tanstack/react-query'
import { deserialize } from './_app/utils/deserialize'

if (!window.ResizeObserver) {
  install()
}

if (!window.AbortController) {
  // @ts-ignore
  import('abortcontroller-polyfill/dist/abortcontroller-polyfill-only')
}

const dataDogGlobalContext = createDatadogRumGlobalContext()
initDatadogRum(dataDogGlobalContext)

if (STAGE === 'production' || STAGE === 'alpha') {
  initSentry()
}

function makeQueryClient() {
  return new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 0,
        retry: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
      },
    },
  })
}

let browserQueryClient: QueryClient | null = null

function getQueryClient() {
  if (isServer) {
    return makeQueryClient()
  } else {
    if (!browserQueryClient) browserQueryClient = makeQueryClient()
    return browserQueryClient
  }
}

function hasChildButNotComment(div: HTMLElement): boolean {
  for (let i = 0; i < div.childNodes.length; i++) {
    const child = div.childNodes[i]
    if (child.nodeType !== Node.COMMENT_NODE) {
      return true
    }
  }
  return false
}

function getStackflowPluginBasicUiTheme() {
  return document.documentElement.dataset.stackflowPluginBasicUiTheme as
    | 'cupertino'
    | 'android'
}
function getURL() {
  return `${window.location.pathname}${
    window.location.search ? window.location.search : ''
  }`
}

const container = document.getElementById('root') as HTMLElement

const webviewConfig = window.__KARROT_SEARCH_INJECTED_CONFIG__?.WEBVIEW_CONFIG

if (hasChildButNotComment(container)) {
  const queryClient = getQueryClient()
  const foundation = window.__KARROT_SEARCH_INJECTED_CONFIG__?.FOUNDATION
  const initialize = window.__KARROT_SEARCH_INJECTED_CONFIG__?.INITIALIZE
  const dehydrate = window.__REACT_QUERY_STATE__
  const deserializedDehydrate = deserialize(dehydrate)

  hydrateRoot(
    container,
    <App
      url={getURL()}
      theme={getStackflowPluginBasicUiTheme()}
      foundation={foundation}
      initialize={initialize}
      webviewConfig={webviewConfig}
      queryClient={queryClient}
      dehydrate={deserializedDehydrate}
    />
  )
} else {
  const root = createRoot(container)
  const queryClient = getQueryClient()

  root.render(
    <StrictMode>
      <App
        url={getURL()}
        theme={getStackflowPluginBasicUiTheme()}
        queryClient={queryClient}
        webviewConfig={webviewConfig}
      />
    </StrictMode>
  )
}
