/**
 * 검색 동네업체 거리 표기 정책
 * @param distance 거리 (m)
 * @returns 거리 표기 (m, km)
 * @see https://www.notion.so/daangn/de5c42f1c3004b36a97588e5050f2097?pvs=4#1c8e6c726a3d43b2b5bf13e8b4fffd15
 */
export const displayLocalProfileDistance = (distance?: number) => {
  if (!distance) {
    return ''
  }

  const shouldDisplayInMeters = distance / 1000 < 1

  if (shouldDisplayInMeters) {
    if (distance < 10) {
      return `${Math.floor(distance)}m` // 10m 미만 정수로만 표시
    }

    return `${Math.floor(distance / 10) * 10}m` // 10m 단위로 표시
  }

  return `${Math.floor(distance / 100) / 10}km` // 소수점 버림, km는 소수점 첫째 자리까지 표시
}
