import { Skeleton } from '../skeleton'
import { Body, Container, Header, Title } from './HotKeywords.css'
import { LABEL } from './constants'

export interface HotKeywordsSkeletonProps {
  className?: string
}

const HotKeywordSkeletonItem = ({ width }: { width: string }) => {
  return (
    <Skeleton
      width={width}
      height="2.1815rem"
      border={{ radius: '1rem' }}
      margin={{
        right: '0.5rem',
      }}
    />
  )
}

const HotKeywordsSkeleton = (props: HotKeywordsSkeletonProps) => {
  return (
    <article className={`${Container} ${props.className ?? ''}`}>
      <header className={Header}>
        <h2 className={Title} a11y-label={LABEL}>
          {LABEL}
        </h2>
      </header>
      <ol className={Body} style={{ display: 'flex' }}>
        <HotKeywordSkeletonItem width="4.5rem" />
        <HotKeywordSkeletonItem width="4rem" />
        <HotKeywordSkeletonItem width="4.3rem" />
        <HotKeywordSkeletonItem width="4.7rem" />
      </ol>
    </article>
  )
}

export default HotKeywordsSkeleton
