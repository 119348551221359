import 'src/packages/business/CardBizPost.css.ts.vanilla.css?source=#H4sIAAAAAAAAA71WUW+bMBB+z6+wJk1aH4wgadOWaC/rU9/2Pk2VMUdyrbGRbZKm0/77hIFAwLRkk/YQxdjn891333128MB0+g3fvitjnyxaAQ9KWoYS9NPT8y6KhArJrwUhKZpCsGNMMgGvmwUhTOBWUrSQm5hwkBb0ZvF7EfQdatiWgp08RX5P1X9MQhISVlpVzTyXxmJ2pFxJC9J2/ieOJSRT0lKDbxCTMLi90ZBXswnjL1utSpnGZM/0F0oNQEoNZwIoV0JputXsSKMwvHL2SqegqWYplqbyFN2dfOVMb1FSjdudrZaW7cIBU7uLSRR0tjuoraJg1cwNgOGqLJRscVk6XApl0KKSMWGJUaK0ULkSkFXHVUOrimY0inMVuXAqENvxxgf15cA22S2D6dwIUQXjaI9VJPeXVwNyJi3ypiCFxpzpo6uHmzk3tqwzbT4OO7Rw5cF4QOSVn36eQ4b8uHf8GPjHnG3H3XLtDhlTusFxHYxIsvYhRD1RDXBiBWja7bgaOKjh52pf17G30hHtJvxc/cb0jtrC7kFnQh1issM0Bbk546kGwSzuwU9JP/OnUBsbtWs3DtFZ+KnkGbilGdp+5pdEdjr0h9H866dPP885I5WE0cac4amV19MSR1PUwGvkuBJlLjvxi6aBzVHSJvnQy3GQtj39tiZfv/tGAlarSOARCXqA5AUtTZSLuf0UKIFywfIiJsv+QqJeqdLodGQP2iJnwukUvFraMQeEwMKgmeLTQemUJhrYS0zcH2XC+dmjwQSFExY3FjCzX299/eputxapu/M6zUg9+p+p90rYtqKL5SS+13XX/q12FVplKODR24z3/YYLPddaf27UXevlhzemD4p/eRVcltykbWvC/D08E5B5GtSi9E7op3AukCLOikpB2q2J2zKDJOvmAXQuHdFq6h0x8ViYrPg5eZsrp3tJ+MEYJhMLZizlOxSpy2tw2sf7M9TGUpVReyyg76J+ZNU88zsxrRfuJ0dfXN/z88i7gFK/q3feaCcK3q88HOxNzqxPdwnM4Mi1T0hMmTzUcY467Qzh5tZpC/0HZ/TyoXcMAAA=';
export var caption = 'CardBizPost_caption__jh11lob';
export var captionIcon = 'CardBizPost_captionIcon__jh11lod';
export var captions = 'CardBizPost_captions__jh11loc';
export var container = 'CardBizPost_container__jh11lo3';
export var content = 'CardBizPost_content__jh11lo7';
export var coupon = 'CardBizPost_coupon__jh11lo2';
export var image = 'CardBizPost_image__jh11lo5';
export var imageContainer = 'CardBizPost_imageContainer__jh11lo4';
export var main = 'CardBizPost_main__jh11lo6';
export var profileImage = 'CardBizPost_profileImage__jh11loa';
export var profileImageContainer = 'CardBizPost_profileImageContainer__jh11lo9';
export var regular = 'CardBizPost_regular__jh11lo1';
export var subContentContainer = 'CardBizPost_subContentContainer__jh11loe';
export var title = 'CardBizPost_title__jh11lo8';
export var titleContainer = 'CardBizPost_titleContainer__jh11lo0';