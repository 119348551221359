import type { ComponentPropsWithRef, ElementType, ReactNode } from 'react'
import classNames from 'classnames'
import { TouchableHighlight } from '../_app/components/touchable-highlight'
import * as css from './CardCommerceItem.css'

interface CardCommerceListItemProps<T extends ElementType> {
  as?: T
  ref?: ComponentPropsWithRef<T>['ref']
  children?: ReactNode
  role?: string
  className?: string
  onClick?: () => void
}

const CardCommerceListItem = <T extends ElementType>({
  as,
  role,
  className,
  children,
  onClick,
  ref,
}: CardCommerceListItemProps<T>) => {
  return (
    <TouchableHighlight
      ref={ref}
      as={as || 'div'}
      role={role}
      className={classNames(css.listItem, className)}
      onClick={onClick}
    >
      {children}
    </TouchableHighlight>
  )
}

export default CardCommerceListItem
