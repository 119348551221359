import type { ReactNode, Ref } from 'react'
import { Children, Fragment, memo, useCallback } from 'react'
import classNames from 'classnames'
import { TAG_GROUP_SIZE } from './constants'
import * as css from './TagGroup.css'
import type * as dividerCss from './TagDivider.css'
import type { RecipeVariants } from '@vanilla-extract/recipes'
import { TagDivider } from './TagDivider'

type TagGroupProps = {
  children: ReactNode
  [TAG_GROUP_SIZE.SMALL]?: boolean
  [TAG_GROUP_SIZE.MEDIUM]?: boolean
  color?: NonNullable<RecipeVariants<typeof dividerCss.dotDivider>>['color']
  UNSAFE_className?: string
  ref?: Ref<HTMLDivElement>
}

const TagGroup = ({
  small = false,
  medium = true,
  color,
  ref,
  ...props
}: TagGroupProps) => {
  const renderTagsWithDotDivider = useCallback(() => {
    return Children.toArray(props.children)
      .filter((child) => Boolean(child))
      .map((child, index, children) => {
        const lastIndexOfChildren = children.length - 1
        if (index < lastIndexOfChildren) {
          return (
            <Fragment key={index}>
              {child}
              <TagDivider
                size={small ? TAG_GROUP_SIZE.SMALL : TAG_GROUP_SIZE.MEDIUM}
              />
            </Fragment>
          )
        }
        return child
      })
  }, [props.children, small])
  return (
    <div
      ref={ref}
      className={classNames(css.tagGroup, props.UNSAFE_className)}
      color={color}
    >
      {renderTagsWithDotDivider()}
    </div>
  )
}

TagGroup.displayName = 'TagGroup'
export default memo(TagGroup)
