import 'src/packages/navbar/SearchInputNavbar.css.ts.vanilla.css?source=#H4sIAAAAAAAAA7VXTW+jMBC991dYWlVqDo7IB9uWntpIlXpZrbSnPVWDmYBVx7aMaZpd7X9fYSCBAAlJ01P4fG/mMfNmMv6FYFjyInVmf8B7COZ1oaQFLtG8vsplvJ6vPfL3ipCIp1rAJiBLgR8PV4SseWSTgEw87zo/DYG9xUZlMqJMCWUC8g7mhtIUMaIprkBazopbVINGQyNcQibs6OHq39W4HcdPiPEJ2Fsrnkl3PCB4LCm3uEoDwlBaNPllDVHEZRwQj3hj3+Cqh84dP6mPimXazXI4TQYCyxxjAxs68bzRXhCT8W0ehQvntoiHkFCZCA01EPEsDYg3nm1vWQMy5ZYrGbTIyczzVunQhAJglr+jy+u0NKZlGgdjORLJAiRDUQUz61ZXqwoewlSJzGK90saTaaVKgjxObPPaTuTxdNqQb6nMKigOBVj8fUN979plxDKT5tlrxauCoWsM37ilFjRNeJyInKlSyWFoMCjtviBKA+N2c/yb7ClR/ywlhkuhD98bAq4F2Dzr10cZGcWjim3uaKzSAfGLvjWlkEc6Yx93kWk0lktVIfs75Ll/CnIF8N0B7LnKUklLU/4HKxxCBJdId99/Vmuij/xRVwJlQ4WqsAatEUyeQUCkkrhruYB4za7ePaAym1PtLgxolPuyUU6pIQYGt/e+zf275+fnQXoFgRbAMFEiQuPEGxCh7yIchF4lkbonaYRMGchrsdm7hTznIDJXUzTMrL0cqME0Ezb9ItSzRehryFuHU7Mu//vMjYexX/6U58PQW215t48/n9018KvzQ/iPBqECvHeAuWEHZNL0bIMCcis7CtUnBrTEaEpR/Mymx8Z4k6SlSbhPU8yQGsN8XhwcYsp3kxemZGs/Yd2z7ZOjphburG9vKC19sFENzKvvc0WF5Z+7oJxAdLEZeYCzVSbYSm/IiD+J4fyVzB+0kvUFuRAqxSfnkK3yXZ60Xn+yqrsL+OySOpjXxYqoxlKBx4Ur5tvKElZcbALCZYKG2/qusd09OtaNrh2ltv10/ZmyG61iAzrZUAEhiik1GGcCDN2+ONrCrMuN6Qyg4tVRa/k6Dar2agGF1qKhaa527msnojXedoCXdtyvMNd66fS5XdI5BSeNCXVoMHVytLyH9wzBSc8I/A9cZgSsKhAAAA==';
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var BackIconContainer = _7a468({defaultClassName:'SearchInputNavbar_BackIconContainer__nfgw4wc',variantClassNames:{platform:{Android:'SearchInputNavbar_BackIconContainer_platform_Android__nfgw4wd',Cupertino:'SearchInputNavbar_BackIconContainer_platform_Cupertino__nfgw4we'}},defaultVariants:{},compoundVariants:[]});
export var CloseButton = _7a468({defaultClassName:'SearchInputNavbar_CloseButton__nfgw4wg',variantClassNames:{platform:{Android:'SearchInputNavbar_CloseButton_platform_Android__nfgw4wh',Cupertino:'SearchInputNavbar_CloseButton_platform_Cupertino__nfgw4wi'}},defaultVariants:{},compoundVariants:[]});
export var CloseButtonContainer = 'SearchInputNavbar_CloseButtonContainer__nfgw4wf';
export var Container = 'SearchInputNavbar_Container__nfgw4w0';
export var Input = _7a468({defaultClassName:'SearchInputNavbar_Input__nfgw4w6',variantClassNames:{platform:{Android:'SearchInputNavbar_Input_platform_Android__nfgw4w7',Cupertino:'SearchInputNavbar_Input_platform_Cupertino__nfgw4w8'}},defaultVariants:{},compoundVariants:[]});
export var InputArea = _7a468({defaultClassName:'SearchInputNavbar_InputArea__nfgw4w9',variantClassNames:{platform:{Android:'SearchInputNavbar_InputArea_platform_Android__nfgw4wa',Cupertino:'SearchInputNavbar_InputArea_platform_Cupertino__nfgw4wb'}},defaultVariants:{},compoundVariants:[]});
export var InputBox = 'SearchInputNavbar_InputBox__nfgw4w2';
export var InputCancel = _7a468({defaultClassName:'SearchInputNavbar_InputCancel__nfgw4w3',variantClassNames:{platform:{Android:'SearchInputNavbar_InputCancel_platform_Android__nfgw4w4',Cupertino:'SearchInputNavbar_InputCancel_platform_Cupertino__nfgw4w5'}},defaultVariants:{},compoundVariants:[]});
export var PageBackContainer = 'SearchInputNavbar_PageBackContainer__nfgw4w1';