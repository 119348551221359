import type { ComponentPropsWithRef, ElementType } from 'react'
import classNames from 'classnames'
import * as css from './TouchableHighlight.css'
import type { AsProps } from '../../../_app/types/as-props'

type TouchableHighlightProps<T extends ElementType> = AsProps<T> & {
  ref?: ComponentPropsWithRef<T>['ref']
}

const TouchableHighlight = <T extends ElementType>({
  as,
  className,
  children,
  ref,
  ...props
}: TouchableHighlightProps<T>) => {
  const Component = as || 'div'

  return (
    <Component
      ref={ref}
      className={classNames(css.container, className)}
      tabIndex={0}
      {...props}
    >
      {children}
    </Component>
  )
}

export default TouchableHighlight
