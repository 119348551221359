import { useMemo } from 'react'
import * as css from './CardShortform.css'
import { Tag } from '../tag'
import { TagGroup } from '../tag-group'
import { IconLocationpinFill } from '@daangn/react-monochrome-icon'
import {
  ADAPTIVE_IMAGE_FORMAT,
  ADAPTIVE_IMAGE_RESIZABLE_OPTION,
  AdaptiveImage,
} from '../adaptive-image'
import { vars } from '@seed-design/design-token'
import { displayLocalProfileDistance } from '../_app/utils/displayLocalProfileDistance'

interface Props {
  thumbnailSrc: string
  title: string
  description: string
  localProfileName: string
  regionName: string
  distance: number
  videoId: string
  fallbackThumbnail: string
}

const CardShortform = (props: Props) => {
  const a11yLabelMainContent = useMemo(() => {
    return `스토리 업체 ${props.localProfileName} 내용 ${props.description} 거리 ${props.distance} 동네 ${props.regionName}`
  }, [
    props.localProfileName,
    props.description,
    props.distance,
    props.regionName,
  ])

  return (
    <div aria-label={a11yLabelMainContent}>
      <div className={css.videoThumbnail}>
        <div className={css.localProfileNameContainer}>
          <IconLocationpinFill
            size={15}
            color={vars.$static.color.staticWhite}
            className={css.localProfileIcon}
          />
          <p className={css.localProfileName}>{props.localProfileName}</p>
        </div>

        <AdaptiveImage
          className={css.image}
          fallbackSrc={props.fallbackThumbnail}
          src={props.thumbnailSrc}
          alt={props.title ? `${props.title} 이미지` : ''}
          options={[
            {
              size: '300x300',
              quality: 82,
              type: ADAPTIVE_IMAGE_RESIZABLE_OPTION.CROP,
              format: ADAPTIVE_IMAGE_FORMAT.WEBP,
            },
          ]}
        />
      </div>
      <p className={css.videoDescription}>{props.description}</p>
      <div className={css.tagGroupContainer}>
        <TagGroup small>
          {props.distance ? (
            <Tag small>{displayLocalProfileDistance(props.distance)}</Tag>
          ) : null}
          {props.regionName ? <Tag small>{props.regionName}</Tag> : null}
        </TagGroup>
      </div>
    </div>
  )
}

export default CardShortform
