import type { ComponentPropsWithRef, ElementType, ReactNode } from 'react'
import classNames from 'classnames'
import { TouchableHighlight } from '../_app/components/touchable-highlight'
import * as css from './CardPlaceItem.css'

interface CardPlaceItemProps<T extends ElementType> {
  as?: T
  ref?: ComponentPropsWithRef<T>['ref']
  role?: string
  className?: string
  children?: ReactNode
  onClick?: () => void
}

const CardPlaceItem = <T extends ElementType>({
  as,
  role,
  className,
  children,
  onClick,
  ref,
}: CardPlaceItemProps<T>) => {
  return (
    <TouchableHighlight
      ref={ref}
      as={as || 'div'}
      role={role}
      className={classNames(
        as === 'li' ? css.listItem : css.container,
        className
      )}
      onClick={onClick}
    >
      {children}
    </TouchableHighlight>
  )
}

export default CardPlaceItem
